import checkoutPageRoute from './checkout.page.route';
import contactPageRoute from './contact.page.route';
import passwordResetPageRoute from './password-reset.page.route';
import privacyPolicyPageRoute from './privacy-policy.page.route';
import sellersPageRoute from './sellers/index.page.route';
import termsAndConditionsPageRoute from './terms-and-conditions.page.route';

const routes = [
  checkoutPageRoute,
  contactPageRoute,
  passwordResetPageRoute,
  privacyPolicyPageRoute,
  sellersPageRoute,
  termsAndConditionsPageRoute,
];

export default ({ urlPathname }: { urlPathname: string }) => ({
  match:
    urlPathname !== '/' &&
    !routes.some((route) => urlPathname.startsWith(route)),
  precedence: -1,
});
